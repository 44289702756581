import React from 'react';
import { motion } from 'framer-motion';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        const delta = 2;
        const range = [];
        const rangeWithDots = [];

        // Always show first page
        range.push(1);

        // Calculate range around current page
        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i > 1 && i < totalPages) {
                range.push(i);
            }
        }

        // Always show last page
        if (totalPages !== 1) {
            range.push(totalPages);
        }

        // Add the page numbers with dots
        let l;
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.5rem',
            marginTop: '2rem',
            flexWrap: 'wrap'
        }}>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                    backgroundColor: currentPage === 1 ? '#e2e8f0' : '#3b82f6',
                    color: currentPage === 1 ? '#a0aec0' : 'white',
                    padding: '0.5rem 1rem',
                    borderRadius: '9999px',
                    fontWeight: '500',
                    border: 'none',
                    cursor: currentPage === 1 ? 'default' : 'pointer',
                    opacity: currentPage === 1 ? '0.5' : '1',
                    minWidth: '100px'
                }}
            >
                Previous
            </motion.button>

            <div style={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                {getPageNumbers().map((number, index) => (
                    number === '...' ? (
                        <span key={`dots-${index}`} style={{
                            color: '#4a5568',
                            padding: '0.5rem'
                        }}>
                            {number}
                        </span>
                    ) : (
                        <motion.button
                            key={number}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => onPageChange(number)}
                            style={{
                                backgroundColor: currentPage === number ? '#3b82f6' : '#e2e8f0',
                                color: currentPage === number ? 'white' : '#4a5568',
                                padding: '0.5rem 1rem',
                                borderRadius: '9999px',
                                fontWeight: '500',
                                border: 'none',
                                cursor: 'pointer',
                                minWidth: '40px'
                            }}
                        >
                            {number}
                        </motion.button>
                    )
                ))}
            </div>

            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{
                    backgroundColor: currentPage === totalPages ? '#e2e8f0' : '#3b82f6',
                    color: currentPage === totalPages ? '#a0aec0' : 'white',
                    padding: '0.5rem 1rem',
                    borderRadius: '9999px',
                    fontWeight: '500',
                    border: 'none',
                    cursor: currentPage === totalPages ? 'default' : 'pointer',
                    opacity: currentPage === totalPages ? '0.5' : '1',
                    minWidth: '100px'
                }}
            >
                Next
            </motion.button>
        </div>
    );
};

export default Pagination; 