import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import { motion, AnimatePresence } from 'framer-motion';
import { getUsername } from '../utils/auth';
import { toast } from 'react-hot-toast';
import SubscriptionCheckoutModal from '../components/SubscriptionCheckoutModal';

const LiveAppPage = () => {
    const { projectId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const iframeRef = useRef(null);
    const [deployedUrl, setDeployedUrl] = useState(null);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const token = localStorage.getItem('token');
    const username = getUsername();
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [subscriptionOptions, setSubscriptionOptions] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3;

    const handleLogin = (e) => {
        e.preventDefault();
        const username = e.target.username.value;
        axios.post('/login', {
            username: username,
            password: e.target.password.value,
        })
            .then((response) => {
                const { token } = response.data;
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                setShowAuthModal(false);
                window.location.reload(); // Reload to reinitialize with token
            })
            .catch((error) => {
                console.error('Login error:', error);
            });
    };

    const handleRegister = (e) => {
        e.preventDefault();
        axios.post('/register', {
            username: e.target.username.value,
            password: e.target.password.value,
        })
            .then((response) => {
                setShowRegisterForm(false); // Switch to login form after registration
            })
            .catch((error) => {
                console.error('Registration error:', error);
            });
    };

    const handleSubscribe = async (type) => {
        try {
            await axios.post(`/api/projects/${projectId}/subscribe`, 
                { type },
                { headers: { Authorization: token } }
            );
            setShowSubscriptionModal(false);
            window.location.reload();
        } catch (error) {
            toast.error('Failed to process subscription');
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const cancelled = params.get('cancelled');
        
        if (cancelled === 'true') {
            navigate(`/live/app/${projectId}`, { replace: true });
            return;
        }

        const fetchDeployment = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`/api/projects/${projectId}/deployment`, {
                    headers: {
                        'Authorization': token
                    }
                });

                const data = await response.json();

                if (response.status === 401 || data.error === 'Invalid or expired token') {
                    localStorage.removeItem('token');
                    localStorage.removeItem('username');
                    setShowAuthModal(true);
                    setIsLoading(false);
                    return;
                }

                if (response.status === 403 && data.subscriptionOptions) {
                    setSubscriptionOptions(data.subscriptionOptions);
                    setShowSubscriptionModal(true);
                    setIsLoading(false);
                    return;
                }

                if (!response.ok) {
                    // If deployment is not ready, retry after delay
                    if (retryCount < maxRetries) {
                        setTimeout(() => {
                            setRetryCount(prev => prev + 1);
                        }, 2000);
                        return;
                    }
                    throw new Error(data.error || 'Failed to load deployment');
                }

                const baseUrl = `http://localhost:${data.ports.frontend}`;
                const configuredUrl = `${baseUrl}?preview_user=${username}&api_port=${data.ports.backend}&refresh=${Date.now()}`;
                setDeployedUrl(configuredUrl);
                setIsLoading(false);
                setRetryCount(0); // Reset retry count on success
            } catch (error) {
                if (error.message.includes('Invalid or expired token')) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('username');
                    setShowAuthModal(true);
                } else {
                    setError(error.message);
                }
                setIsLoading(false);
            }
        };

        if (!token) {
            setShowAuthModal(true);
            setIsLoading(false);
            return;
        }

        fetchDeployment();
    }, [projectId, token, username, location.search, navigate, retryCount]);

    useEffect(() => {
        // Add message handler for iframe requests
        const handleMessage = (event) => {
            if (event.data.type === 'GET_USERNAME') {
                const iframe = iframeRef.current;
                if (iframe && iframe.contentWindow) {
                    iframe.contentWindow.postMessage({
                        type: 'SET_USERNAME',
                        username: username
                    }, '*');
                }
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [username]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const cancelled = urlParams.get('cancelled');
        
        if (cancelled === 'true') {
            toast.info('Subscription was cancelled');
            // Clear the URL parameters
            window.history.replaceState({}, '', window.location.pathname);
        }
    }, []);

    if (isLoading) return (
        <div className="flex items-center justify-center h-screen">
            <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-500 border-t-transparent"></div>
        </div>
    );
    
    if (error) return <div>Error: {error}</div>;

    if (showAuthModal) {
        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                    <h2 className="text-2xl font-bold mb-4">
                        {showRegisterForm ? 'Register' : 'Login'}
                    </h2>
                    <form onSubmit={showRegisterForm ? handleRegister : handleLogin}>
                        <input
                            type="text"
                            name="username"
                            placeholder="Username"
                            className="w-full p-2 mb-4 border rounded"
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="w-full p-2 mb-4 border rounded"
                            required
                        />
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            {showRegisterForm ? 'Register' : 'Login'}
                        </button>
                    </form>
                    <button
                        onClick={() => setShowRegisterForm(!showRegisterForm)}
                        className="w-full mt-4 text-blue-500 hover:text-blue-600"
                    >
                        {showRegisterForm ? 'Already have an account? Login' : 'Need an account? Register'}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="h-screen relative">
            {isLoading ? (
                <div>Loading deployed application...</div>
            ) : (
                <>
                    {deployedUrl && (
                        <div className={`h-full ${showSubscriptionModal ? "opacity-50" : ""}`}>
                            <iframe
                                ref={iframeRef}
                                src={deployedUrl}
                                className="w-full h-full border-0"
                                style={{ minHeight: '100vh' }}
                                title="Live Preview"
                                onLoad={() => {
                                    const iframe = iframeRef.current;
                                    if (iframe && iframe.contentWindow) {
                                        iframe.contentWindow.postMessage({
                                            type: 'SET_USERNAME',
                                            username: username
                                        }, '*');
                                    }
                                }}
                            />
                        </div>
                    )}
                    
                    {showSubscriptionModal && subscriptionOptions && (
                        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
                            <SubscriptionCheckoutModal
                                projectId={projectId}
                                subscriptionOptions={subscriptionOptions}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default LiveAppPage;
