import React, {useEffect, useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ProjectsPage from './pages/ProjectsPage';
import ProjectDetailsPage from './pages/ProjectDetailsPage';
import LiveAppPage from './pages/LiveAppPage';

function App() {
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    useEffect(() => {
        const storedSelectedProjectId = localStorage.getItem('selectedProjectId');
        if (storedSelectedProjectId) {
            setSelectedProjectId(storedSelectedProjectId);
        }
    }, []);

    const handleProjectSelect = (projectId) => {
        setSelectedProjectId(projectId);
        localStorage.setItem('selectedProjectId', projectId);
    };

    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/projects" element={<ProjectsPage handleProjectSelect={handleProjectSelect} />} />
            <Route path="/edit" element={<ProjectDetailsPage selectedProjectId={selectedProjectId} />} />
            <Route path="/live/app/:projectId/*" element={<LiveAppPage />} />
        </Routes>
    );
}

export default App;
