import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';

const SubscriptionSettings = ({ projectId, initialSettings, onSave }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [settings, setSettings] = useState(() => ({
        monthly: {
            price: Number(initialSettings?.monthly?.price || 0) / 100,
            enabled: Boolean(initialSettings?.monthly?.enabled)
        },
        yearly: {
            price: Number(initialSettings?.yearly?.price || 0) / 100,
            enabled: Boolean(initialSettings?.yearly?.enabled)
        }
    }));

    useEffect(() => {
        if (initialSettings) {
            setSettings({
                monthly: {
                    price: Number(initialSettings.monthly?.price || 0) / 100,
                    enabled: Boolean(initialSettings.monthly?.enabled)
                },
                yearly: {
                    price: Number(initialSettings.yearly?.price || 0) / 100,
                    enabled: Boolean(initialSettings.yearly?.enabled)
                }
            });
        }
    }, [initialSettings]);

    const handleSave = async () => {
        try {
            const settingsInCents = {
                monthly: {
                    ...settings.monthly,
                    price: Math.round(settings.monthly.price * 100)
                },
                yearly: {
                    ...settings.yearly,
                    price: Math.round(settings.yearly.price * 100)
                }
            };

            const response = await axios.post(
                `/api/projects/${projectId}/subscription-settings`, 
                settingsInCents,
                { headers: { Authorization: localStorage.getItem('token') } }
            );
            
            if (response.data.success) {
                toast.success('Subscription settings saved');
                onSave(response.data.subscriptionOptions);
                setIsOpen(false);
            }
        } catch (error) {
            toast.error('Failed to save subscription settings');
            console.error('Error saving subscription settings:', error);
        }
    };

    return (
        <>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsOpen(true)}
                className="px-4 py-2 bg-purple-500 text-white rounded-full hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-colors duration-300"
            >
                Subscription Settings
            </motion.button>

            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full"
                    >
                        <h2 className="text-2xl font-bold mb-4">Subscription Settings</h2>
                        
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-lg font-semibold mb-2">Monthly Subscription</h3>
                                <div className="flex items-center space-x-4">
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={settings.monthly.enabled}
                                            onChange={(e) => setSettings(prev => ({
                                                ...prev,
                                                monthly: { ...prev.monthly, enabled: e.target.checked }
                                            }))}
                                            className="mr-2"
                                        />
                                        Enable
                                    </label>
                                    <input
                                        type="number"
                                        value={settings.monthly.price}
                                        onChange={(e) => setSettings(prev => ({
                                            ...prev,
                                            monthly: { 
                                                ...prev.monthly, 
                                                price: Math.max(0, parseInt(e.target.value) || 0) 
                                            }
                                        }))}
                                        className="border rounded px-2 py-1 w-24"
                                        placeholder="Price"
                                        min="0"
                                    />
                                    <span>USD/month</span>
                                </div>
                            </div>

                            <div>
                                <h3 className="text-lg font-semibold mb-2">Yearly Subscription</h3>
                                <div className="flex items-center space-x-4">
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={settings.yearly.enabled}
                                            onChange={(e) => setSettings(prev => ({
                                                ...prev,
                                                yearly: { ...prev.yearly, enabled: e.target.checked }
                                            }))}
                                            className="mr-2"
                                        />
                                        Enable
                                    </label>
                                    <input
                                        type="number"
                                        value={settings.yearly.price}
                                        onChange={(e) => setSettings(prev => ({
                                            ...prev,
                                            yearly: { 
                                                ...prev.yearly, 
                                                price: Math.max(0, parseInt(e.target.value) || 0) 
                                            }
                                        }))}
                                        className="border rounded px-2 py-1 w-24"
                                        placeholder="Price"
                                        min="0"
                                    />
                                    <span>USD/year</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end space-x-4 mt-6">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
                            >
                                Save
                            </button>
                        </div>
                    </motion.div>
                </div>
            )}
        </>
    );
};

export default SubscriptionSettings; 