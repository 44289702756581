const getUsername = () => {
    // First check if username is directly stored
    const directUsername = localStorage.getItem('username');
    if (directUsername) return directUsername;
    
    // Fall back to extracting from token
    const token = localStorage.getItem('token');
    if (!token) return null;
    
    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.username;
    } catch (error) {
        console.error('Error parsing token:', error);
        return null;
    }
};

export { getUsername }; 