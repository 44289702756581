import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const navigate = useNavigate();
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(true);

    const handleRegister = (e) => {
        e.preventDefault();
        axios.post('/register', {
            username: e.target.username.value,
            password: e.target.password.value,
        })
            .then((response) => {
                console.log(response.data.message);
                // Optionally, log the user in after registration
            })
            .catch((error) => {
                console.error('Registration error:', error);
            });
    };

    const handleLogin = (e) => {
        e.preventDefault();
        axios.post('/login', {
            username: e.target.username.value,
            password: e.target.password.value,
        })
            .then((response) => {
                const { token } = response.data;
                console.log('Login successful:', token);
                localStorage.setItem('token', token);
                localStorage.setItem('username', e.target.username.value);
                navigate('/projects');
            })
            .catch((error) => {
                console.error('Login error:', error);
            });
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col min-h-screen bg-white text-gray-900"
        >
            <header className="border-b border-gray-200">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
                    <motion.h1
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        className="text-2xl font-semibold flex items-center"
                    >
                        SaaS Quick
                        <span className="bg-blue-100 text-blue-600 ml-2 px-2 py-1 rounded-full text-xs font-normal">Beta</span>
                    </motion.h1>
                    <nav className="flex items-center space-x-4">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                                setShowAuthModal(true);
                                setShowRegisterForm(true);
                            }}
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                        >
                            Get Started
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                                setShowAuthModal(true);
                                setShowRegisterForm(false);
                            }}
                            className="bg-gray-100 text-gray-900 px-4 py-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-300"
                        >
                            Login
                        </motion.button>
                    </nav>
                </div>
            </header>

            <main className="flex-grow">
                {/* Hero Section */}
                <section className="py-20 bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
                    <div className="container mx-auto px-4 text-center">
                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.4 }}
                        >
                            <h2 className="text-5xl font-bold mb-6">Build Your App 10x Faster</h2>
                            <p className="text-xl mb-8 max-w-2xl mx-auto">
                                No coding knowledge? No problem! Our platform offers a seamless chat-based interface with a live interactive preview. Deploy changes effortlessly and share your live app URL with the world.
                            </p>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => {
                                    setShowAuthModal(true);
                                    setShowRegisterForm(true);
                                }}
                                className="bg-white text-blue-600 font-semibold py-3 px-8 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 transition-colors duration-300 text-lg"
                            >
                                Start Building Now
                            </motion.button>
                        </motion.div>
                    </div>
                </section>

                {/* Features Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.6 }}
                            className="grid grid-cols-1 md:grid-cols-2 gap-8"
                        >
                            <div>
                                <h3 className="text-2xl font-semibold mb-4">Chat-Based App Building</h3>
                                <p className="text-gray-600 mb-4">
                                    Interact with our smart chat interface to describe your app. Instantly see a live preview of your app as you build it, without writing a single line of code.
                                </p>
                                <ul className="space-y-2 text-gray-600">
                                    <li>• User-friendly chat interface</li>
                                    <li>• Real-time live preview of your app</li>
                                    <li>• No coding required</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold mb-4">Effortless Deployment</h3>
                                <p className="text-gray-600 mb-4">
                                    Deploy your app to production with just a click. Manage multiple deployments and share your live app URLs with stakeholders or users.
                                </p>
                                <ul className="space-y-2 text-gray-600">
                                    <li>• One-click deployments</li>
                                    <li>• Manage multiple versions</li>
                                    <li>• Share live app URLs instantly</li>
                                </ul>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* Interactive Demo Section */}
                <section className="py-20 bg-gray-50">
                    <div className="container mx-auto px-4 text-center">
                        <motion.h2
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            className="text-4xl font-bold mb-6"
                        >
                            Experience the Power of No-Code Development
                        </motion.h2>
                        <motion.p
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.4 }}
                            className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto"
                        >
                            Our platform combines the ease of chat-based interactions with the robustness of live previews and seamless deployments. Get started today and transform your development workflow.
                        </motion.p>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                                setShowAuthModal(true);
                                setShowRegisterForm(true);
                            }}
                            className="bg-blue-500 text-white font-semibold py-3 px-8 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300 text-lg"
                        >
                            Get Started for $20
                        </motion.button>
                    </div>
                </section>

                {/* Pricing Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4 text-center">
                        <motion.h2
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            className="text-4xl font-bold mb-6"
                        >
                            Simple Pricing
                        </motion.h2>
                        <motion.p
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.4 }}
                            className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto"
                        >
                            For just $20 per project, enjoy seamless no-code editing and reliable hosting. Focus on your ideas while we handle the technicalities.
                        </motion.p>
                        <div className="flex justify-center">
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="bg-white border border-gray-200 rounded-xl shadow-lg p-6 max-w-sm w-full"
                            >
                                <h3 className="text-2xl font-semibold mb-4">Basic Plan</h3>
                                <p className="text-4xl font-bold text-blue-600 mb-4">$20</p>
                                <p className="text-gray-600 mb-6">per project</p>
                                <ul className="space-y-2 text-left text-gray-600 mb-6">
                                    <li>• No-code editing</li>
                                    <li>• Reliable hosting</li>
                                    <li>• Live interactive preview</li>
                                    <li>• 24/7 Support</li>
                                </ul>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => {
                                        setShowAuthModal(true);
                                        setShowRegisterForm(true);
                                    }}
                                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300 w-full"
                                >
                                    Get Started
                                </motion.button>
                            </motion.div>
                        </div>
                    </div>
                </section>

                {/* Testimonials Section */}
                <section className="py-20 bg-gray-50">
                    <div className="container mx-auto px-4 text-center">
                        <motion.h2
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            className="text-4xl font-bold mb-6"
                        >
                            What Our Users Say
                        </motion.h2>
                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.4 }}
                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                        >
                            <div className="bg-white p-6 rounded-xl shadow-lg">
                                <p className="text-gray-600 mb-4">
                                    "SaaS Quick has transformed the way I develop applications. The AI-powered chat interface is a game-changer!"
                                </p>
                                <h4 className="text-lg font-semibold">- Jane Doe</h4>
                            </div>
                            <div className="bg-white p-6 rounded-xl shadow-lg">
                                <p className="text-gray-600 mb-4">
                                    "Affordable pricing and reliable hosting make SaaS Quick my go-to solution for all my projects."
                                </p>
                                <h4 className="text-lg font-semibold">- John Smith</h4>
                            </div>
                            <div className="bg-white p-6 rounded-xl shadow-lg">
                                <p className="text-gray-600 mb-4">
                                    "The live interactive preview allows me to see changes in real-time, saving so much development time."
                                </p>
                                <h4 className="text-lg font-semibold">- Emily White</h4>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* Features Expanded Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <motion.h2
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            className="text-4xl font-bold text-center mb-12"
                        >
                            Why Choose SaaS Quick?
                        </motion.h2>
                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.4 }}
                            className="grid grid-cols-1 md:grid-cols-3 gap-8"
                        >
                            <div className="text-center">
                                <img src="/icons/chat.svg" alt="Chat Interface" className="mx-auto mb-4 w-16 h-16" />
                                <h3 className="text-2xl font-semibold mb-2">Intuitive Chat Interface</h3>
                                <p className="text-gray-600">
                                    Our chat-based system makes app building as simple as having a conversation. Describe your needs, and watch your app come to life.
                                </p>
                            </div>
                            <div className="text-center">
                                <img src="/icons/live-preview.svg" alt="Live Preview" className="mx-auto mb-4 w-16 h-16" />
                                <h3 className="text-2xl font-semibold mb-2">Real-Time Live Preview</h3>
                                <p className="text-gray-600">
                                    See your changes instantly with our live preview feature. No more waiting – make adjustments on the fly and see results immediately.
                                </p>
                            </div>
                            <div className="text-center">
                                <img src="/icons/deployment.svg" alt="Effortless Deployment" className="mx-auto mb-4 w-16 h-16" />
                                <h3 className="text-2xl font-semibold mb-2">Effortless Deployment</h3>
                                <p className="text-gray-600">
                                    Deploy your projects to production with a single click. Manage deployments easily and share your live app URLs without hassle.
                                </p>
                            </div>
                            <div className="text-center">
                                <img src="/icons/support.svg" alt="24/7 Support" className="mx-auto mb-4 w-16 h-16" />
                                <h3 className="text-2xl font-semibold mb-2">24/7 Support</h3>
                                <p className="text-gray-600">
                                    Our dedicated support team is available around the clock to help you with any questions or issues you may encounter.
                                </p>
                            </div>
                            <div className="text-center">
                                <img src="/icons/affordable.svg" alt="Affordable Pricing" className="mx-auto mb-4 w-16 h-16" />
                                <h3 className="text-2xl font-semibold mb-2">Affordable Pricing</h3>
                                <p className="text-gray-600">
                                    For just $20 per project, gain access to our full suite of no-code editing and hosting tools. Quality development doesn't have to break the bank.
                                </p>
                            </div>
                            <div className="text-center">
                                <img src="/icons/customizable.svg" alt="Highly Customizable" className="mx-auto mb-4 w-16 h-16" />
                                <h3 className="text-2xl font-semibold mb-2">Highly Customizable</h3>
                                <p className="text-gray-600">
                                    Tailor your projects to meet your specific needs with our intuitive no-code editor. Flexibility at your fingertips.
                                </p>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* Call to Action Section */}
                <section className="py-20 bg-blue-500 text-white">
                    <div className="container mx-auto px-4 text-center">
                        <motion.h2
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            className="text-4xl font-bold mb-6"
                        >
                            Ready to Transform Your Development Workflow?
                        </motion.h2>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                                setShowAuthModal(true);
                                setShowRegisterForm(true);
                            }}
                            className="bg-white text-blue-500 font-semibold py-3 px-8 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 transition-colors duration-300 text-lg"
                        >
                            Get Started for $20
                        </motion.button>
                    </div>
                </section>
            </main>

            {/* Authentication Modal */}
            <AnimatePresence>
                {showAuthModal && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    >
                        <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            className="bg-white rounded-xl p-8 max-w-md w-full shadow-lg"
                        >
                            {showRegisterForm ? (
                                <form onSubmit={handleRegister} className="flex flex-col gap-4">
                                    <h2 className="text-2xl font-semibold text-center mb-4">Create Your Account</h2>
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        required
                                        className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        required
                                        className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    <div className="flex justify-center">
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            type="submit"
                                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                                        >
                                            Register
                                        </motion.button>
                                    </div>
                                </form>
                            ) : (
                                <form onSubmit={handleLogin} className="flex flex-col gap-4">
                                    <h2 className="text-2xl font-semibold text-center mb-4">Login to Your Account</h2>
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        required
                                        className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        required
                                        className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    <div className="flex justify-between items-center">
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            type="submit"
                                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                                        >
                                            Login
                                        </motion.button>
                                        <button
                                            type="button"
                                            onClick={() => setShowRegisterForm(true)}
                                            className="text-blue-500 hover:underline focus:outline-none"
                                        >
                                            New user? Create account
                                        </button>
                                    </div>
                                </form>
                            )}
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                style={{
                                    marginTop: '1.5rem',
                                    width: '100%',
                                    backgroundColor: '#f7fafc',
                                    color: '#4a5568',
                                    padding: '0.5rem 1rem',
                                    borderRadius: '9999px',
                                    fontWeight: '600',
                                    transition: 'all 300ms',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setShowAuthModal(false)}
                            >
                                Close
                            </motion.button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

export default LandingPage;
