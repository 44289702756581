import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const SubscriptionCheckoutModal = ({ subscriptionOptions, projectId }) => {
    const handleSubscribe = async (type) => {
        try {
            const response = await axios.post(
                `/api/projects/${projectId}/create-subscription-session`,
                { type },
                { headers: { Authorization: localStorage.getItem('token') } }
            );

            window.location.href = response.data.sessionUrl;
        } catch (error) {
            toast.error('Failed to initiate subscription');
            console.error('Subscription error:', error);
        }
    };

    return (
        <div className="bg-white p-8 rounded-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">Subscribe to Access</h2>
            <p className="mb-6">A subscription is required to access this application:</p>
            
            <div className="space-y-4">
                {subscriptionOptions.monthly.enabled && (
                    <div className="border rounded-lg p-4">
                        <h3 className="text-lg font-semibold">Monthly Plan</h3>
                        <p className="text-2xl font-bold text-blue-600">
                            ${(subscriptionOptions.monthly.price / 100).toFixed(2)}/month
                        </p>
                        <button
                            onClick={() => handleSubscribe('monthly')}
                            className="mt-4 w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        >
                            Subscribe Monthly
                        </button>
                    </div>
                )}
                
                {subscriptionOptions.yearly.enabled && (
                    <div className="border rounded-lg p-4">
                        <h3 className="text-lg font-semibold">Yearly Plan</h3>
                        <p className="text-2xl font-bold text-green-600">
                            ${(subscriptionOptions.yearly.price / 100).toFixed(2)}/year
                        </p>
                        <p className="text-sm text-gray-600 mb-2">
                            ${((subscriptionOptions.yearly.price / 12) / 100).toFixed(2)} per month when billed annually
                        </p>
                        <button
                            onClick={() => handleSubscribe('yearly')}
                            className="mt-4 w-full py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                        >
                            Subscribe Yearly
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionCheckoutModal; 