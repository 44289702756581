import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { debounce } from 'lodash';
import { toast } from 'react-hot-toast';
import { getUsername } from '../utils/auth';
import SubscriptionSettings from '../components/SubscriptionSettings';

const ProjectDetailsPage = ({ selectedProjectId }) => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [tickets, setTickets] = useState([]);
    const [livePreviewUrl, setLivePreviewUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const [projectPhase, setProjectPhase] = useState('');
    const [isDeploying, setIsDeploying] = useState(false);
    const [isInitializing, setIsInitializing] = useState(true);
    const [initializationError, setInitializationError] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const socketRef = useRef(null);
    const messagesEndRef = useRef(null);
    const [previewInteractions, setPreviewInteractions] = useState([]);
    const iframeRef = useRef(null);
    const [changesMade, setChangesMade] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [shouldRefreshIframe, setShouldRefreshIframe] = useState(false);
    const [deployedUrl, setDeployedUrl] = useState(null);
    const [liveAppUrl, setLiveAppUrl] = useState(null);
    const [hasBeenDeployed, setHasBeenDeployed] = useState(false);
    const username = getUsername();
    const [project, setProject] = useState(null);

    const debouncedRefreshIframe = useCallback(
        debounce(() => {
            if (changesMade && !isRefreshing) {
                console.log('Refreshing iframe:', new Date().toISOString());
                setIsRefreshing(true);
                const iframe = iframeRef.current;
                if (iframe) {
                    const baseUrl = livePreviewUrl.split('?')[0];
                    const refreshUrl = `${baseUrl}?refresh=${Date.now()}`;
                    console.log('New iframe src:', refreshUrl);
                    iframe.src = refreshUrl;
                }
            }
        }, 2000),
        [livePreviewUrl, changesMade, isRefreshing]
    );

    const checkForChanges = useCallback(async () => {
        if (changesMade) {
            try {
                console.log('Changes detected, refreshing preview...');
                debouncedRefreshIframe();
                setChangesMade(false);
            } catch (error) {
                console.error('Error refreshing preview:', error);
            }
        }
    }, [changesMade, debouncedRefreshIframe]);

    useEffect(() => {
        if (shouldRefreshIframe && !isRefreshing) {
            const iframe = iframeRef.current;
            if (iframe) {
                console.log('Refreshing iframe:', new Date().toISOString());
                setIsRefreshing(true);
                
                const handleLoad = () => {
                    console.log('Iframe loaded, sending SET_USERNAME message');
                    
                    // Send message multiple times to ensure it's received
                    const sendUsername = () => {
                        iframe.contentWindow.postMessage({ 
                            type: 'SET_USERNAME',
                            username: username
                        }, '*');
                    };

                    // Send immediately after load
                    sendUsername();
                    
                
                    setIsRefreshing(false);
                    setShouldRefreshIframe(false);
                    setChangesMade(false);
                };

                iframe.addEventListener('load', handleLoad, { once: true });
                
                const baseUrl = livePreviewUrl.split('?')[0];
                if (iframe.src !== baseUrl) {
                    iframe.src = baseUrl;
                }

                return () => iframe.removeEventListener('load', handleLoad);
            }
        }
    }, [shouldRefreshIframe, isRefreshing, livePreviewUrl]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        if (selectedProjectId) {
            setIsInitializing(true);
            setInitializationError(null);
            initializeProject();
        }
        
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [selectedProjectId]);

    useEffect(() => {
        console.log('Setting up socket connection...');
        const socket = io('/', {
            auth: { token: localStorage.getItem('token') }
        });
        
        
        socket.emit('join_project', selectedProjectId);
        console.log('Joined project room:', selectedProjectId);
        
        
        return () => {
            socket.disconnect();
        };
    }, [selectedProjectId]);

    const fetchMessages = useCallback(async () => {
        try {
            const response = await axios.get(`/api/projects/${selectedProjectId}/messages`, {
                headers: { Authorization: localStorage.getItem('token') },
            });
            console.log('Messages received:', response.data);
            if (response.data && Array.isArray(response.data)) {
                setMessages(response.data);
            } else {
                console.error('Invalid messages data received:', response.data);
                setMessages([]);
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
            setMessages([]);
        }
    }, [selectedProjectId]);

    const fetchProjectDetails = async () => {
        try {
            const response = await axios.get(`/api/projects/${selectedProjectId}`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            
            if (response.data) {
                // Ensure we have subscription options with defaults
                const subscriptionOptions = {
                    monthly: {
                        price: response.data.subscriptionOptions?.monthly?.price ?? 0,
                        enabled: response.data.subscriptionOptions?.monthly?.enabled ?? false
                    },
                    yearly: {
                        price: response.data.subscriptionOptions?.yearly?.price ?? 0,
                        enabled: response.data.subscriptionOptions?.yearly?.enabled ?? false
                    }
                };

                setProject({
                    ...response.data,
                    subscriptionOptions
                });
                
                setHasBeenDeployed(!!response.data.deployedUrl);
                setDeployedUrl(response.data.deployedUrl);
            }
        } catch (error) {
            console.error('Error fetching project details:', error);
            toast.error('Failed to load project details');
        }
    };

    const initializeProject = async () => {
        try {
            console.log('Initializing project:', selectedProjectId);
            const controller = new AbortController();
            const timeout = setTimeout(() => controller.abort(), 300000); // 5 minutes timeout

            const response = await axios.post(
                `/api/projects/${selectedProjectId}/initialize`,
                {},
                {
                    headers: { Authorization: localStorage.getItem('token') },
                    signal: controller.signal,
                    timeout: 300000
                }
            );

            clearTimeout(timeout);
            console.log('Initialization response:', response.data);
            await fetchMessages();
            
            if (response.data.livePreviewUrl) {
                const { livePreviewUrl, liveAppUrl, frontendPort, backendPort } = response.data;
                console.log('Setting preview URLs:', { livePreviewUrl, liveAppUrl });

                const baseUrl = livePreviewUrl.split('?')[0];
                const configuredUrl = `${baseUrl}?preview_user=${username}&api_port=${backendPort}&refresh=${Date.now()}&rand=${Math.random()}`;
                
                setLivePreviewUrl(configuredUrl);
                setLiveAppUrl(liveAppUrl);

                // Add iframe load handler
                const iframe = iframeRef.current;
                if (iframe) {
                    const handleLoad = () => {
                        console.log('Iframe loaded after initialization, sending SET_USERNAME message');
                        const sendUsername = () => {
                            iframe.contentWindow.postMessage({ 
                                type: 'SET_USERNAME',
                                username: username
                            }, '*');
                        };
                        sendUsername();
                    };

                    iframe.addEventListener('load', handleLoad);
                    iframe.src = configuredUrl;
                }

                setIsInitializing(false);
                initializeSocket();
            } else {
                console.log('Starting polling for initialization');
                await pollForInitializationCompletion();
            }
        } catch (error) {
            console.error('Project initialization failed:', error);
            setIsInitializing(false);
            toast.error('Project initialization failed. Please try again.');
        }
    };

    const pollForInitializationCompletion = async () => {
        const pollInterval = 5000;
        const maxAttempts = 12;
        let attempts = 0;

        while (attempts < maxAttempts) {
            await new Promise(resolve => setTimeout(resolve, pollInterval));

            try {
                const response = await axios.get(`/api/projects/${selectedProjectId}`, {
                    headers: { Authorization: localStorage.getItem('token') },
                });

                if (response.data.initialized) {
                    console.log('Project initialization completed:', response.data);
                    setLivePreviewUrl(response.data.livePreviewUrl);
                    setIsInitializing(false);
                    initializeSocket();
                    return;
                }
            } catch (error) {
                console.error('Error polling for project initialization:', error);
            }

            attempts++;
        }

        setInitializationError('Project initialization timed out. Please try again.');
        setIsInitializing(false);
    };

    const initializeSocket = () => {
        const token = localStorage.getItem('token');
        const socket = io('/', {
            auth: { token },
            query: { projectId: selectedProjectId }
        });

        socket.on('connect', () => {
            console.log('Socket connected');
            socket.emit('join_project', { projectId: selectedProjectId });
        });

        socket.on('preview_updated', (data) => {
            console.log('Received preview_updated event:', data);
            if (data.previewUrl && !isRefreshing && !shouldRefreshIframe) {
                setLivePreviewUrl(data.previewUrl);
                setShouldRefreshIframe(true);
            }
        });

        socket.on('container_restarted', () => {
            console.log('Container restarted, scheduling refresh');
            if (!isRefreshing && !shouldRefreshIframe) {
                setTimeout(() => {
                    setShouldRefreshIframe(true);
                    setChangesMade(true);
                }, 2000);
            }
        });

        socketRef.current = socket;
    };

    const handleSendMessage = async () => {
        console.log('Sending message:', inputMessage);
        if (inputMessage.trim() === '') return;

        setIsSendingMessage(true);
        const newMessage = { role: 'user', content: inputMessage };
        const updatedMessages = [...messages, newMessage];
        setMessages(updatedMessages);
        setInputMessage('');

        const systemMessage = {
            role: 'system',
            content: '🔄 Analyzing your request and generating code updates... This may take a minute or two. The live preview will refresh when the changes are applied!'
        };
        setMessages(prev => [...prev, systemMessage]);

        try {
            const response = await axios.post('/api/chat',
                { message: inputMessage, projectId: selectedProjectId, messages: updatedMessages },
                { headers: { Authorization: localStorage.getItem('token') } }
            );
            console.log('Chat response received:', response.data);
            
            setMessages(prev => prev.filter(msg => msg !== systemMessage));
            
            const assistantMessage = { role: 'assistant', content: response.data.message };
            const finalMessages = [...updatedMessages, assistantMessage];
            setMessages(finalMessages);
            
            try {
                await axios.post(`/api/projects/${selectedProjectId}/messages`, 
                    { messages: finalMessages },
                    { headers: { Authorization: localStorage.getItem('token') } }
                );
            } catch (error) {
                console.error('Error saving messages:', error);
            }

            if (response.data.previewUrl) {
                setLivePreviewUrl(response.data.previewUrl);
                setChangesMade(true);
            }
            if (response.data.tickets) {
                setTickets(response.data.tickets);
            }

            if (response.data.changesApplied) {
                setChangesMade(true);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => [
                ...prev.filter(msg => msg !== systemMessage),
                { 
                    role: 'system', 
                    content: '❌ An error occurred while processing your request. Please try again.'
                }
            ]);
        } finally {
            setIsSendingMessage(false);
        }
    };

    const saveMessages = async (messagesToSave) => {
        try {
            await axios.post(`/api/projects/${selectedProjectId}/messages`, 
                { messages: messagesToSave },
                { headers: { Authorization: localStorage.getItem('token') } }
            );
            console.log('Messages saved successfully');
        } catch (error) {
            console.error('Error saving messages:', error);
        }
    };

    const handleDeploy = async () => {
        setIsDeploying(true);
        try {
            const response = await axios.post(`/api/deploy/${selectedProjectId}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            });
            
            if (response.data.success) {
                setHasBeenDeployed(true);
                toast.success('Deployment started!');
                pollForDeploymentCompletion();
            } else {
                setIsDeploying(false);
                toast.error(`Deployment failed: ${response.data.error}`);
            }
        } catch (error) {
            console.error('Deployment error:', error);
            toast.error('An error occurred during deployment.');
            setIsDeploying(false);
        }
    };

    const handleBackToProjects = () => navigate('/projects');

    const fetchDeploymentStatus = async () => {
        try {
            const response = await axios.get(`/api/projects/${selectedProjectId}/deployment`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            
            if (response.data.success && response.data.deployedUrl) {
                setDeployedUrl(`/live/app/${selectedProjectId}`);
                setHasBeenDeployed(true);
            } else {
                setHasBeenDeployed(false);
                setDeployedUrl(null);
            }
        } catch (error) {
            console.error('Error fetching deployment status:', error);
            setHasBeenDeployed(false);
            setDeployedUrl(null);
        }
    };

    useEffect(() => {
        if (selectedProjectId) {
            fetchProjectDetails();
        }
    }, [selectedProjectId]);

    const pollForDeploymentCompletion = async () => {
        const pollInterval = 5000;
        const maxAttempts = 12;
        let attempts = 0;

        while (attempts < maxAttempts) {
            await new Promise(resolve => setTimeout(resolve, pollInterval));

            try {
                const response = await axios.get(`/api/projects/${selectedProjectId}/deployment`, {
                    headers: { Authorization: localStorage.getItem('token') },
                });

                if (response.data.ports?.frontend && response.data.ports?.backend) {
                    console.log('Deployment completed:', response.data);
                    setDeployedUrl(`/live/app/${selectedProjectId}`);
                    toast.success('Deployment completed successfully!');
                    setIsDeploying(false);
                    navigate(`/live/app/${selectedProjectId}`);
                    return;
                }
            } catch (error) {
                console.error('Error polling for deployment completion:', error);
            }

            attempts++;
        }

        toast.error('Deployment timed out. Please try again.');
        setIsDeploying(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (shouldRefreshIframe) {
                setShouldRefreshIframe(false);
            }
        }, 5000);
        return () => clearTimeout(timer);
    }, [shouldRefreshIframe]);

    if (initializationError) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <h2 className="text-2xl font-semibold mb-4">Initialization Error</h2>
                    <p className="text-red-600">{initializationError}</p>
                    <button
                        onClick={fetchProjectDetails}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                    >
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    const Spinner = () => (
        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    );

  
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col min-h-screen bg-white text-gray-900"
        >
            <header className="border-b border-gray-200 py-4">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                    <div className="flex items-center gap-4">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={handleBackToProjects}
                            className="bg-gray-100 text-gray-900 px-4 py-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-300"
                        >
                            ← Back to Projects
                        </motion.button>
                        <motion.h1
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            className="text-2xl font-semibold flex items-center"
                        >
                            SaaS Quick
                            <span className="bg-blue-100 text-blue-600 ml-2 px-2 py-1 rounded-full text-xs font-normal">Beta</span>
                        </motion.h1>
                    </div>
                    <div className="flex items-center">
                        {/* Empty div to maintain spacing */}
                    </div>
                </div>
            </header>

            <main className="flex-grow flex">
                <div className="w-2/5 p-4 flex flex-col">
                    <div className="flex-grow overflow-y-auto mb-4 h-[calc(100vh-200px)]">
                        {messages.map((message, index) => (
                            <div key={index} className={`mb-4 ${message.role === 'user' ? 'text-right' : ''}`}>
                                <div className={`inline-block p-2 rounded-lg ${message.role === 'user' ? 'bg-blue-100' : 'bg-gray-100'}`}>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="flex mt-4">
                        <input
                            type="text"
                            value={inputMessage}
                            disabled={isInitializing || isSendingMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                            className="flex-grow p-2 border rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Type your message..."
                        />
                        <button
                            disabled={isInitializing || isSendingMessage}
                            onClick={handleSendMessage}
                            className="px-4 py-2 bg-blue-500 text-white rounded-r-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                        >
                            {isSendingMessage ? <Spinner /> : 'Send'}
                        </button>
                    </div>
                </div>
                <div className="w-3/5 p-4 flex flex-col">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-2xl font-semibold">Interactive App Preview</h2>
                        <div className="flex gap-4">
                            <button
                                onClick={handleDeploy}
                                disabled={isDeploying || isInitializing || isSendingMessage}
                                className="px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-300"
                            >
                                {isDeploying ? 'Deploying...' : 'Deploy App'}
                            </button>
                            {(hasBeenDeployed && deployedUrl) && (
                                <a
                                    href={deployedUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300 inline-flex items-center"
                                >
                                    View Deployed App
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                </a>
                            )}
                            { (
                                <SubscriptionSettings 
                                    projectId={selectedProjectId}
                                    initialSettings={project?.subscriptionOptions || {
                                        monthly: { price: 0, enabled: false },
                                        yearly: { price: 0, enabled: false }
                                    }}
                                    onSave={(settings) => {
                                        setProject(prev => ({
                                            ...prev,
                                            subscriptionOptions: settings
                                        }));
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex-grow border rounded-lg overflow-hidden">
                        {isInitializing ? (
                            <div className="text-center mt-4">
                                <h2 className="text-2xl font-semibold mb-4">Initializing Project</h2>
                                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 mx-auto"></div>
                            </div>
                        ) : isRefreshing ? (
                            <div className="flex items-center justify-center h-full">
                                <p>Applying changes and refreshing preview...</p>
                            </div>
                        ) : (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                style={{ height: '100%' }}
                            >
                                <iframe
                                    ref={iframeRef}
                                    src={livePreviewUrl}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                        pointerEvents: isRefreshing ? 'none' : 'auto'
                                    }}
                                    onLoad={() => {
                                        const iframe = iframeRef.current;
                                        if (iframe) {
                                            iframe.contentWindow.postMessage({ 
                                                type: 'SET_USERNAME',
                                                username: username
                                            }, '*');
                                        }
                                    }}
                                />
                            </motion.div>
                        )}
                    </div>
                </div>
            </main>
        </motion.div>
    );
};

export default ProjectDetailsPage;